import React from 'react';

interface ButtonProps {
    onClick: () => void;
    text: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, text }) => {
    return (
        <button className='p-3 flex justify-center items-center box-border px-4 py-2 gap-2 w-48 h-10 bg-gray-200 border-2 border-ridelGreen rounded-full text-ridelGreen' onClick={onClick} style={{ fontFamily: 'gilroyextrabold' }}>
            {text}
        </button>
    );
};

export default Button;