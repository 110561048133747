import React from 'react';
import { CompanyInfo } from './types';
import Nets from '../../../../components/Nets/Nets';

const CompanyContactInformation: React.FC<{ contactInfo: CompanyInfo | undefined }> = ({ contactInfo }) => {
    return (
        <div className="justify-between items-center p-6 space-y-8 bg-white shadow-md rounded-lg">
            <div className="w-full order-0">
                <div className="items-start gap-8 w-full  border-b-2 border-ridelGreen">
                    <div className="justify-between items-center w-full flex">
                        <div className="text-xl" style={{ fontFamily: 'gilroyextrabold', flex: 1 }}>
                            CONTACT INFORMATION:
                        </div>
                        <div className="text-xl" style={{ fontFamily: 'gilroyextrabold', flex: 1, textAlign: 'right' }}>
                            {contactInfo?.companyName}
                        </div>
                    </div>
                </div>

                <div className="items-start w-full">
                    <div className="p-10 w-full">
                        <div className="items-center justify-between w-full flex">
                            <div className="text-base font-semibold" style={{ fontFamily: 'Rubik' }}>
                                Company Phone number
                            </div>
                            <div className="text-base font-semibold" style={{ fontFamily: 'Rubik' }}>
                                {contactInfo?.companyPhoneNumber}
                            </div>
                        </div>
                    </div>
                    <div className=" justify-between items-center p-10 gap-10 w-full ">
                        <div className=" items-center justify-between w-full flex">
                            <div className="font-semibold text-base" style={{ fontFamily: 'Rubik' }}>
                                Company Address
                            </div>
                            <div className="font-semibold text-base" style={{ fontFamily: 'Rubik' }}>
                                {contactInfo?.address}
                            </div>
                        </div>
                    </div>
                    <div className=" justify-between items-center p-10 w-full border-b-2 border-solid">
                        <div className=" items-center w-full">
                            <div className="font-semibold text-2xl" style={{ fontFamily: 'gilroyextrabold' }}>
                                Representative:
                            </div>
                        </div>
                    </div>
                    <div className=" justify-between items-center p-10  w-full">
                        <div className=" items-center justify-between w-full flex">
                            <div className="font-semibold text-base" style={{ fontFamily: 'Rubik' }}>
                                Phone number
                            </div>
                            <div className="font-semibold text-base">
                                {contactInfo?.representativePhoneNumber}
                            </div>
                        </div>
                    </div>
                    <div className="justify-between items-center p-10 w-full" style={{ fontFamily: 'Rubik' }}>
                        <div className=" items-center justify-between w-full flex">
                            <div className="font-semibold text-base" style={{ fontFamily: 'Rubik' }}>
                                Name
                            </div>
                            <div className="font-semibold text-base ml-auto" style={{ fontFamily: 'Rubik' }}>
                                {contactInfo?.representativeName}
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    );
};

export default CompanyContactInformation;