import React, { useState } from 'react';
import TextInputWithLabel from '../Input/TextInputWithLabel';
import PrimaryButton from '../Buttons/PrimaryButton';
import RidelLogo from '../../images/RidelLogo.png';
import { CompanyInfo } from '../../views/Register/types';
import { registerCompany } from '../../api/company';
import  Spinner  from '../Spinner/Spinner';
import { useNavigate } from 'react-router';
export interface FormProps {
    fields: { [key: string]: { placeholder: string } };
    onSubmit: (data: CompanyInfo) => void;
    buttonText: string;
}

const DynamicForm: React.FC<FormProps> = ({ fields, onSubmit, buttonText }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<CompanyInfo>({
        companyEmail: '',
        companyPhoneNumber: '',
        companyName: '',
        companyNo: '',
        address: '',
        zip: '',
        city: '',
        representativeEmail: '',
        representativePhoneNumber: '',
        representativeName: '',
    });



        

        const handleClick = async () => {
            try {
                setIsLoading(true);
                var result = await registerCompany(formData);
                setIsLoading(false);
                console.log(result);
                navigate('/register/success')
            } catch (error) {
                setIsLoading(false);
                console.error('Error sending form data:', error);

            }
        };
    

    const fieldLabels: { [key: string]: string } = {
        companyEmail: 'E-post adresse',
        companyPhoneNumber: 'Telefonnummer',
        companyName: 'Firmanavn',
        companyNo: 'Firma nummer',
        address: 'Adresse',
        zip: 'Postnummer',
        city: 'By',
        representativeEmail: 'Kontaktperson e-post',
        representativePhoneNumber: 'Kontaktperson telefonnummer',
        representativeName: 'Kontaktperson navn',
    };



    

        return (
            <div className='justify-between items-center p-15 space-y-20 bg-white rounded-lg shadow-md'>
                <div className='p-3 mt-3  '>
                    <div className='flex justify-center mb-6'>
                        <img src={RidelLogo} alt='RidelLogo' className='w-115.5 h-42.58' />
                    </div>
                    <div className='grid grid-cols-2 gap-4'>
                        {Object.entries(formData).map(([fieldName, fieldValue]) => (
                            <div key={fieldName} className='p-4'>
                                <TextInputWithLabel
                                    label={fieldLabels[fieldName]}
                                    value={fieldValue}
                                    onChange={(newValue: string) =>
                                        setFormData((prevData) => ({ ...prevData, [fieldName]: newValue }))
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <div className='p-3 flex justify-center mb-6'>
                        {isLoading ? (
                            <Spinner /> 
                        ) : (
                            <PrimaryButton onClick={handleClick} text={buttonText} ></PrimaryButton>
                        )}
                    </div>
                </div>
            </div>
        );
    };

export default DynamicForm;