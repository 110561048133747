import { Trip } from "../views/Dashboard/components/TripElement/trip";
import { createBearerTokenHeader } from "./headers";



const url = process.env.REACT_APP_API_URL;

export const getCompanyTrips = (userToken:string, id:number, fromDate:string,toDate:string):Promise<Array<Trip>> => {
    return fetch(`${url}/companyTrips/${id}?From=${fromDate}&To=${toDate}`, {
        method: 'GET',
        headers: createBearerTokenHeader(userToken),
    })
        .then(response => response.json() as Promise<Trip[]>)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
}