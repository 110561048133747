import { createContext, useContext, useState } from 'react';
import { User } from '../views/Login/types';
import { storageRead } from '../utils/Storage';
import React from 'react';
interface UserContextState {
    user: User | null;
    setUser: (user: User | null) => void;
}

const UserContext = createContext<UserContextState | undefined>(undefined);

export const useUser = (): UserContextState => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};



function UserProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<User | null>(storageRead('user'));

    const state: UserContextState = {
        user,
        setUser,
    };

    return (
        <UserContext.Provider value={state}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;