import React, { useState } from 'react';
import Edit from '../../../images/Icons/edit-icon.png';
import Delete from '../../../images/Icons/delete-icon.png';
import { Employee } from './UserOverView/types';
import { useUser } from '../../../contexts/UserProvider';
import EditEmployeeDialog from '../../Users/components/EditUser';
import { editCompanyEmployee } from '../../../api/company';


interface UserListItemDetailedProps {
    employee: Employee;
    setCompanyEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
    onDeleteUser: (userId: number) => void;
}

const UserListItemDetailed: React.FC<UserListItemDetailedProps> = ({ employee, setCompanyEmployees, onDeleteUser }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [newEmployee, setNewEmployee] = useState(employee);

    const userContext = useUser();
    const user = userContext.user;

    const deleteUser = async () => {
        onDeleteUser(employee.companyEmployeeId);
    };

    const editUser = async () => {
        setShowDialog(true);
    };


    const saveChanges = async (editedEmployee: Employee) => {
        try {
            const result = await editCompanyEmployee(editedEmployee, user!.token, employee.companyEmployeeId);
            if (result) {
                window.alert('Endringer lagret');
                
                setNewEmployee(editedEmployee);
    
                setCompanyEmployees(prevEmployees =>
                    prevEmployees.map(emp =>
                        emp.companyEmployeeId === editedEmployee.companyEmployeeId ? editedEmployee : emp
                    )
                );
            } else {
                window.alert('Feilet å lagre endringer');
            }
        } catch (error) {
            console.error('Feil ved redigering av bruker:', error);
        } finally {
            setShowDialog(false);
        }
    };





            return (
                <div className='flexbox flex items-center p-10 gap-20 w-full'>
                    <div className="order-0 w-3 h-3 bg-ridelGreen transform -rotate-y-180 rounded-full"></div>
                    <div className="order-1 flex items-center" style={{ fontFamily: 'Rubik', width: '300px', paddingLeft: '20px' }}>
                        {employee.name}
                    </div>
                    <div className="order-2 flex items-center" style={{ fontFamily: 'Rubik', width: '300px', paddingLeft: '20px' }}>
                        {employee.phoneNumber}
                    </div>
                    <div className="order-3 flex items-center" style={{ fontFamily: 'Rubik', width: '300px', paddingLeft: '20px' }}>
                        {employee.email}
                    </div>
                    <div className="order-4 flex items-center gap-2" style={{ width: '200px', paddingLeft: '20px' }}>
                        <img className='cursor-pointer' src={Edit} onClick={editUser} alt="Edit" />
                        <img className='cursor-pointer ms-5' src={Delete} onClick={deleteUser} alt="Delete" />
                        {showDialog && (
                            <EditEmployeeDialog
                                onClose={() => setShowDialog(false)}
                                onFormCompleted={saveChanges}
                                employee={newEmployee}
                            />
                        )}
                    </div>
                </div>
            );
        
        
};

export default UserListItemDetailed;
