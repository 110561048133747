import React from 'react';
import { RegisterCardResponse } from '../components/Nets/types';
const url = process.env.REACT_APP_API_URL;
export async function registerCardNets() {
    return fetch(`${url}/payment`, {
        method: 'POST',
    })
        .then(response => response.json())
        .then(result => {
            console.log(result);
            return result as RegisterCardResponse;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
}