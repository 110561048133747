import React, { useEffect } from 'react';
import withAuth from '../../hoc/withAuth';
import StatisticsContainer from './components/StatisticsContainer';
import UserOverview from './components/UserOverView/UserOverview';
import CompanyContactInformation from './components/CompanyContactInformation/CompanyContactInformation';
import TripList from './components/TripList';
import RidelLogo from '../../images/RidelLogo.png'
import Navbar from '../../components/Navbar';
import { useUser } from '../../contexts/UserProvider';
import { useState } from 'react';
import { getCompanyInfoById } from '../../api/company';
import { CompanyInfo } from './components/CompanyContactInformation/types';
const Dashboard: React.FC = () => {

    const userContext = useUser();
    const user = userContext.user;
    const [companyinfo, setCompanyinfo] = useState<CompanyInfo | null>();
    const dateToday = new Date();


    useEffect(() => {
        const fetchData = async () => {
            const result = await getCompanyInfoById(user!.companyId, user!.token);
            setCompanyinfo(result);
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar/>
            <div className='flex  flex-col items-start ml-12 p-16 gap-6 bg-gray-100 flex-none order-1 self-stretch flex-grow-1'>
                <div className='grid grid-cols-9 flex flex-row items-start p-0 gap-6 flex-none order-0 self-stretch flex-grow-0'>
                    <div className='flex flex-col col-span-2 items-start p-0 gap-6  flex-none order-0 self-stretch flex-grow-0'>
                        <StatisticsContainer count={5} type='Turer' date={dateToday} user={user!}/>
                        <StatisticsContainer count={4678} type='Kr' date={dateToday} user={user!}/>
                    </div>
                    <div className='col-span-3'>
                        <UserOverview isDashboard={true}/>
                    </div>
                    <div className='col-span-4'>
                        {companyinfo && <CompanyContactInformation contactInfo={companyinfo} />}
                    </div>
                </div>
                <TripList/>
                <div className='flex justify-end gap-10 w-full'>
                    <img src={RidelLogo} style={{width:'115.5px', height:'42.58px'}}></img>
                </div>
            </div>
        </div>
    );
}

export default withAuth(Dashboard);