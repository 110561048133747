import React from 'react';
import CancelIcon from '../../../../images/Icons/status-icon-cancel.png';

interface Props {
    
}

const Canceled: React.FC<Props> = () => {
    return (
        <div className="flex flex-row items-center gap-5">
            <div className="font-semibold text-base flex items-center text-cancelYellow" style={{ fontFamily:'Rubik' }}>
                CANCELED
            </div>
            <div>
                <img src={CancelIcon}  />
            </div>
        </div>
    );
};

export default Canceled;