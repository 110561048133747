import React, { useState, useEffect } from 'react';
import TripElement from './TripElement/TripElement';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ExportIcon from '../../../images/Icons/export-icon.png';
import { getCompanyTrips } from '../../../api/companyTrips';
import { useUser } from '../../../contexts/UserProvider';
import { Trip } from './TripElement/trip';

interface Props {
    showExportButton?: boolean;
    onClickExport?: () => void;
}

const TripList: React.FC<Props> = ({ showExportButton = false, onClickExport = ()=>{} }) => {
    const userContext = useUser();
    const [tripList, setTripList] = useState<Trip[]>([]);

    useEffect(() => {
        const fetchTrips = async () => {
            try {
                const trips = await getCompanyTrips(userContext.user!.token, userContext.user!.companyId, '2023-03-03T00:00:00', '2024-12-12T00:00:00');
                setTripList(trips);
            } catch (error) {
                console.error('Error fetching trips:', error);
            }
        };

        fetchTrips();
    }, [userContext.user?.token, userContext.user?.companyId]);

    return (
        <div className='flex flex-col p-6 gap-8 relative rounded-lg w-full shadow-md'>
            <div className="flex flex-col items-start gap-8">
                <div className="text-2xl flex text-grey-200 border-b-2 border-ridelGreen w-full" style={{ fontFamily: 'gilroyextrabold' }}>
                    TRIPS:
                </div>
            </div>
            <table className='table-auto'>
                <tbody>
                    {tripList.map((trip) => (
                        <TripElement key={trip.dispatcherBookingNumber} trip={trip} />
                    ))}
                </tbody>
            </table>
            
     
            {showExportButton && (
                <div className="flex flex-row justify-end items-center p-0 h-76 flex-none order-2 self-stretch flex-grow-0">
                    <PrimaryButton text="Eksporter" onClick={onClickExport} icon={ExportIcon}/>
                </div>
            )}
        </div>
    );
};

export default TripList;
