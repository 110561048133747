import { ComponentType } from "react"
import { useUser } from "../contexts/UserProvider"
import { Navigate } from "react-router-dom"

//Higher order component used to check if the user context is empty. Redirects the user to the login page if no user object is found.
const withAuth = (Component) => (props) =>{
    const userContext = useUser()
    console.log('user', userContext.user)
    if(userContext.user !== null){
        return <Component {...props} />
    }else{
        return <Navigate to='/' />
    }
}
export default withAuth
