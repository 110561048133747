import React, { useState, useEffect } from 'react';
import { editUser, logInUser, updatePassword } from '../../api/users';
import {  storageSave } from '../../utils/Storage';
import RidelLogo from '../../images/RidelLogo.png';
import TextInputWithoutLabel from '../../components/Input/TextInputWithoutLabel';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import {  useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { useUser } from '../../contexts/UserProvider';

const UpdatePasswordView: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
    const { token } = useParams<{ token: string }>();



    const navigate = useNavigate();
    const userContext = useUser();
    const handlePasswordChange = (value: string) => {
        setPassword(value);
    };
    useEffect(() => {
        if (token) {
            setForgotPasswordMode(true);
        }
    }, [token]);

    const handlePasswordConfirmationChange = (value: string) => {
        setConfirmPassword(value);
    };

    const handleLogin = async () => {
        setLoading(true);
        try {
            if(forgotPasswordMode){
                const [error, response] = await updatePassword({password: password, passwordConfirmation: password, token: token?? ''});
                if (error) {
                    console.log(error);
                    storageSave('user', null);
                    userContext.setUser(null);
                } else {
                    if(response.username !== null){
                        const [loginError, user] = await logInUser({email:response.username,password: password});
                        if (loginError) {
                            console.log(loginError);
                            storageSave('user', null);
                            userContext.setUser(null);
                        } else {
                        storageSave('user', user);
                        userContext.setUser(user);    
                        navigate('/dashboard');
                        
                    }
                    }
                }
            }else{
            const [error, response] = await editUser({id :userContext.user!.id,email:userContext.user!.email, companyId:userContext.user!.companyId,password: password, passwordConfirmation: password,passwordChangeRequired:false },userContext.user!.token ?? '');
            if (error) {
                console.log(error);
                storageSave('user', null);
                userContext.setUser(null);
            } else {
                if(response === 0){
                    navigate('/dashboard');
                }
                
            }
        }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };




    return (
        <div className='flex flex-col justify-center items-center p-2 gap-2  w-screen h-screen sm:w-300'>
            <div className='justify-between items-center p-8 space-y-8 bg-white rounded-lg shadow-md sm:w-300'>
                <div className='flex flex-col items-center gap-8 mx-auto sm:w-300'>
                    <div>
                        <img src={RidelLogo} alt="RidelLogo" className='w-115.5 h-42.58'/>
                    </div>
                    <div className='lg-flex xs:flex-none w-full flex-col   px-2 py-2 gap-6'>  
                    
                        <TextInputWithoutLabel className=''value={password} type="password" placeHolder='Passord' onChange={handlePasswordChange}  />
                        <TextInputWithoutLabel className='mt-5'value={confirmPassword} type="password" placeHolder='Bekreft passord' onChange={handlePasswordConfirmationChange}  />
                              
                        

                    </div>   
                </div>
                <div className='flex justify-center items-start px-8 gap-4 mx-auto'>
                    {loading === false && 
                    <>
                    <PrimaryButton onClick={handleLogin} text='LAGRE'></PrimaryButton>
                    </>
                   }
                   {loading && <Spinner />}
                </div>
            </div>
        </div>
    );
};

export default UpdatePasswordView;
