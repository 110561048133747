import { useState } from "react";
import TextInputWithLabel from "../../../components/Input/TextInputWithLabel";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import {  Employee } from "../../Dashboard/components/UserOverView/types";
import { registerUser } from "../../../api/users";
import { useUser } from "../../../contexts/UserProvider";

const EditEmployeeDialog: React.FC<{ onClose: () => void , onFormCompleted: (newEmployee: Employee) => void,  employee: Employee }> = ({ onClose, onFormCompleted, employee }) => {
    const [name, setName] = useState(employee.name);
    const [phoneNumber, setPhoneNumber] = useState(employee.phoneNumber);    
    const [email, setEmail] = useState(employee.email);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const userContext = useUser();
    const userToken = userContext.user?.token;
    const handleSubmit = () => {
        const updatedEmployee: Employee = {
            companyId: employee.companyId,
            name: name,
            phoneNumber: phoneNumber,
            email: email,
            companyEmployeeId: employee.companyEmployeeId,
        };
        onFormCompleted(updatedEmployee);
        if(isAdminUser){
            const tempPassword = generateFirstTimePassword();
            const { firstName, lastName } = separateName(name);
            registerUser({email: email,companyId:employee.companyId, password: tempPassword,passwordConfirmation:tempPassword ,firstName: firstName??'',lastName:lastName, token:'',phoneNumber: phoneNumber, passwordChangeRequired: true}, userToken ?? '');
        }
        onClose();
    };
    const generateFirstTimePassword = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters[randomIndex];
        }
        return password;
    }
    const separateName = (name: string) => {
        const nameParts = name.split(" ");
        const firstName = nameParts.shift(); 
        const lastName = nameParts.join(" "); 
        return { firstName, lastName };
      };
    const handleIsAdminUserChanged = () => {
        setIsAdminUser(!isAdminUser);
    }
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-whiteBackground bg-opacity-50 p-10">
            <div className="bg-white p-20 rounded-lg">
                <h1 className='flex justify-center' style={{fontFamily: 'gilroyextrabold'}}>Rediger ansatt</h1>
                <TextInputWithLabel label="Navn" value={name} onChange={setName}/>
                <TextInputWithLabel label="Telefonnummer" value={phoneNumber} onChange={setPhoneNumber}/>
                <TextInputWithLabel label="E-post" value={email} onChange={setEmail} />
                <div className='flex items-center justify-left px-2 mt-5' style={{ fontFamily: 'gilroyextrabold' }}>
                            <input type="checkbox" checked={isAdminUser} onChange={handleIsAdminUserChanged} className='mr-2' />
                            <strong className='text-primary'>Aministrator</strong>
                </div>
                <div className="p-2">
                    <PrimaryButton text="Lagre" onClick={handleSubmit}></PrimaryButton>

                </div> 
                <div className="p-2">
                <SecondaryButton text="Avbryt" onClick={onClose}></SecondaryButton>
                </div>
            </div>
        </div>
    );
};

export default EditEmployeeDialog;
