import React from 'react';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const RegistrationCompleted: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-col justify-center items-center p-2 gap-2  w-screen h-screen'>
            <div className='justify-between items-center p-6 space-y-8 bg-white shadow-md rounded-lg'>    
                <div className='text-5xl' style={{ fontFamily: 'gilroyextrabold'}}>Registrering fullført</div>
                <p className='text-xl'style={{ fontFamily: 'Rubik' }}> Takk for at du registrerte deg! Du vil motta en tilbakemelding på E-post så fort noen har gått igjennom registreringen din</p>
                <PrimaryButton text='Tilbake til innlogging' onClick={() => navigate('/')} />
            </div>
        </div>
    );
};

export default RegistrationCompleted;
