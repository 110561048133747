import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import TextInputWithLabel from '../../components/Input/TextInputWithLabel';
import RidelLogo from '../../images/RidelLogo.png';
import { CompanyInfo } from '../Register/types';
import { get } from 'http';
import { useUser } from '../../contexts/UserProvider';
import { getCompanyInfoById, updateCompanyInfo } from '../../api/company';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Nets from '../../components/Nets/Nets';
interface CompanyInfoViewProps {
    
}

const CompanyInfoView: React.FC = ({
    
}) => {
    const userContext = useUser();
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const info = await getCompanyInfoById(userContext.user!.companyId, userContext.user!.token);
            setCompanyInfo(info);
        };

        fetchCompanyInfo();
    }, [userContext.user]);
    
    
    const saveChanges = async () => {
        var result = await updateCompanyInfo(companyInfo!, userContext.user!.token, userContext.user!.companyId);

        if(result){
            window.alert('Endringer lagret');
        }
    };



    return (
        <div>
            <Navbar></Navbar>
            <div className='flex flex-col ml-12 items-start p-8 gap-6'>
                <div className='flex ml-8 flex-col items-start gap-6 p-8' style={{
                    boxShadow: '0px 4px 14.4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '5px',
                    flex: 'none',
                    order: 0,
                    alignSelf: 'stretch',
                    flexGrow: 1,
                }}>
                    <div className="flex flex-row items-end p-0 flex-none order-0 self-stretch flex-grow-0 font-semibold text-28 leading-32.81 w-full border-b-2 border-ridelGreen text-2xl" style={{ fontFamily: 'gilroyextrabold' }}> 
                        KONTAKTINFORMASJON:
                    </div>
                    {companyInfo && (
                        <div className="flex flex-row grid grid-cols-9 items-start p-5 gap-5 w-full">
                            <div className="col-span-4">
                                <TextInputWithLabel label="Firmanavn" value={companyInfo.companyName} onChange={(e) => setCompanyInfo({ ...companyInfo, companyName: e })} />
                            </div>
                            <div className="col-span-2">
                                <TextInputWithLabel label="Organisasjonsnummer" value={companyInfo.companyNo} onChange={(e) => setCompanyInfo({ ...companyInfo, companyNo: e })}/>
                            </div>
                            <div className="col-span-3">
                                <TextInputWithLabel label="Telefonnummer" value={companyInfo.companyPhoneNumber} onChange={(e) => setCompanyInfo({ ...companyInfo, companyPhoneNumber: e })}/>
                            </div>
                        </div>
                    )}
                    {companyInfo && (
                        <div className="flex flex-row grid grid-cols-7 items-start p-5 gap-5 w-full">
                            <div className="col-span-3">
                                <TextInputWithLabel label="Adresse" value={companyInfo.address} onChange={(e) => setCompanyInfo({ ...companyInfo, address: e })}/>
                            </div>
                            <div className="col-span-1">
                                <TextInputWithLabel label="Postkode" value={companyInfo.zip} onChange={(e) => setCompanyInfo({ ...companyInfo, zip: e })}/>
                            </div>
                            <div className="col-span-3">
                                <TextInputWithLabel label="Poststed" value={companyInfo.city} onChange={(e) => setCompanyInfo({ ...companyInfo, city: e })}/>
                            </div>
                        </div>
                    )}
                    {companyInfo && (
                        <div className="flex w-full grid grid-cols-7 flex-row items-start p-5 gap-5">
                            <div className="col-span-4">
                                <TextInputWithLabel label="Kontaktperson" value={companyInfo.representativeName} onChange={(e) => setCompanyInfo({ ...companyInfo, representativeName: e })}/>
                            </div>
                            <div className="col-span-3">
                                <TextInputWithLabel label="Kontaktperson tlf" value={companyInfo.representativePhoneNumber} onChange={(e) => setCompanyInfo({ ...companyInfo, representativePhoneNumber: e })}/>
                            </div>
                        </div>
                    )}

                    <div className='flex justify-between w-full'>

                        <Nets></Nets>                        
                        <PrimaryButton text="Lagre endringer" onClick={saveChanges} />                        
                    </div>

                </div>
                <div className='flex justify-end gap-10 w-full mt-10'>
                    <img src={RidelLogo} style={{width:'115.5px', height:'42.58px'}}></img>
                </div>

            </div>
        </div>
    );
};

export default CompanyInfoView;