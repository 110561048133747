import { User, LoginProps, RegisterProps, UpdateUserProps, UpdatePasswordProps } from "../views/Login/types";
import { createBearerTokenHeader } from "./headers";
const apiURL = process.env.REACT_APP_API_URL;

export async function logInUser(loginCredentials: LoginProps): Promise<[Error | null, User | null]> {
  try {
    const response = await fetch(`${apiURL}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginCredentials)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to log in');
    }

    const data = await response.json();
    return [null, data];
  } catch (error: any) {
    console.error(error.message);
    return [error, null];
  }
}

export async function registerUser(user: RegisterProps, userToken: string): Promise<[Error | null, User | null]> {
  try {
    const response = await fetch(`${apiURL}/user/createUser`, {
      method: 'POST',
      headers: createBearerTokenHeader(userToken)
      ,
      body: JSON.stringify(user)
    });

    const data = await response.json();
    return [null, data];
  } catch (error: any) {
    console.log(error.message)
    return [error, null];
  }
}
export async function forgotPassword(email: string): Promise<[Error | null, any | null]> {
  try{
  const response = await fetch(`${apiURL}/user/forgotPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
    ,
    body: JSON.stringify(email)
  });

  const data = await response.json();
  return [null, data];
} catch (error: any) {
  console.log(error.message)
  return [error, null];
}
}

export async function editUser(user: UpdateUserProps, userToken: string): Promise<[Error | null, any | null]> {
  try {
    const response = await fetch(`${apiURL}/user/updateUser`, {
      method: 'PUT',
      headers: createBearerTokenHeader(userToken)
      ,
      body: JSON.stringify(user)
    });

    const data = await response.json();
    return [null, data];
  } catch (error: any) {
    console.log(error.message)
    return [error, null];
  }
}

export async function updatePassword(updatePassword: UpdatePasswordProps): Promise<[Error | null, any | null]> {
  try {
    const response = await fetch(`${apiURL}/user/change-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
      ,
      body: JSON.stringify(updatePassword)
    });

    const data = await response.json();
    return [null, data];
  } catch (error: any) {
    console.log(error.message)
    return [error, null];
  }
}