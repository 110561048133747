import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { getTotalAmountSpentForMonth } from '../../../api/company';
import { User } from '../../Login/types';

interface StatisticsContainerProps {
    count: number;
    type: string;
    date: Date;
    user: User;
}

interface StatisticsContainerState {
    count: number;
    type: string;
    date: Date;
    user:User;
}
function getMonthRange(inputDate: Date): { fromDate: Date, toDate: Date } {
    const fromDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), 1);

    const toDate = new Date(inputDate.getFullYear(), inputDate.getMonth() + 1, 0);

    return { fromDate, toDate };
}

class StatisticsContainer extends React.Component<StatisticsContainerProps, StatisticsContainerState> {
    constructor(props: StatisticsContainerProps) {
        super(props);
        this.state = {
            count: props.count,
            type: props.type,
            date: props.date,
            user: props.user
        };
    }
    
    

    goLeft = async () => {
        const { fromDate, toDate } = getMonthRange(new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, 1));
        if (this.state.date.getMonth() === 0) {
            const newDate = new Date(this.state.date.getFullYear() - 1, 11);
            const newCount = await getTotalAmountSpentForMonth(this.state.user.companyId, fromDate, toDate, this.state.user.token);
            this.setState({ 
                date: newDate,
                count: newCount
            });
        } else {
            const newDate = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1);
            const newCount = await getTotalAmountSpentForMonth(this.state.user.companyId, fromDate, toDate, this.state.user.token);
            this.setState({
                date: newDate,
                count: newCount
            });
        }
    }
    goRight = async () => {
        const { fromDate, toDate } = getMonthRange(new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, 1));
        if (this.state.date.getMonth() === 11) {
            const newDate = new Date(this.state.date.getFullYear() + 1, 0);
            const newCount = await getTotalAmountSpentForMonth(this.state.user.companyId, fromDate, toDate, this.state.user.token);
            this.setState({ 
                date: newDate,
                count: newCount
            });
        } else {
            const newDate = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1);
            const newCount = await getTotalAmountSpentForMonth(this.state.user.companyId, fromDate, toDate, this.state.user.token);
            this.setState({
                date: newDate,
                count: newCount
            });
        }
    }

    render() {
        const { count, type } = this.state;
        return (
            <div className="flex flex-col justify-center items-center p-8 space-y-6 w-355.03 h-237 bg-white shadow-md rounded-lg">
                <div className="box-border flex flex-row justify-center border-b-2 border-ridelGreen items-end p-6 gap-4 w-189 h-89">
                    <div className='text-4xl' style={{ fontFamily: 'gilroyextrabold' }}>{count}</div>
                    <div className='text-4xl' style={{ fontFamily: 'gilroyextrabold' }}>{type}</div>
                </div>
                <div className='flex items-center gap-14 w-291.03 h-28'>
                    <FaAngleLeft onClick={this.goLeft}/>
                     <div className="text-base text-gray-700" style={{ fontFamily: 'Rubik' }}>
                      {`${this.state.date.toLocaleString('nb-NO',{month:'long'})} ${this.state.date.getFullYear()}`}
                     </div>
                    <FaAngleRight onClick={this.goRight}/>
                </div>
            </div>
        );
    }
}

export default StatisticsContainer;