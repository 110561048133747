import { User } from "../views/Login/types"

//Function to set the value of the session storage
export const storageSave = (key:string, value:User | null) => {
    sessionStorage.setItem(key,JSON.stringify(value))
}

//Function to read the value of the session storage.
export const storageRead = (key: string) => {
    const data = sessionStorage.getItem(key);
    if (data) {
        return JSON.parse(data);
    }

    return null;
}
