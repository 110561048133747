import React from 'react';
import Navbar from '../../components/Navbar';
import UserOverview from '../Dashboard/components/UserOverView/UserOverview';
import RidelLogo from '../../images/RidelLogo.png'
import withAuth from '../../hoc/withAuth';
interface Props {
    
}

const UsersView: React.FC<Props> = () => {
    return (
    <div>
        <Navbar />
        <div className="p-16 ml-12  justify-between">
            <UserOverview isDashboard={false}/>
            <div className='flex justify-end gap-10 w-full mt-10'>
                <img src={RidelLogo} style={{width:'115.5px', height:'42.58px'}}></img>
            </div>
        </div>
    </div>
    );
};

export default withAuth(UsersView);