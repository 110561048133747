import React, { useState } from 'react';

interface TextInputProps {
    label: string;
    value: string;
    onChange?: (newValue: string) => void;
}

const TextInputWithLabel: React.FC<TextInputProps> = ({ label, value, onChange=()=>{} }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <div className='p-3'>
            <div className='ml-2 flex items-start p-0 font-semibold text-28 leading-32.81' style={{ fontFamily: 'Rubik' }}>
                <label>{label}</label>
            </div>
            <div className='flex appearance-none items-center bg-transparent focus:outline-none p-2 gap-2 bg-white border border-ridelGreen rounded-full' style={{ boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.25)' }}>
                <input type="text" value={value} onChange={handleChange} className="w-full" />
            </div>
        </div>
    );
};

export default TextInputWithLabel;