import React from 'react';
import { Employee } from './UserOverView/types';

interface UserListItemDashBoardProps {
    employee: Employee;
}

const UserListItemDashBoard: React.FC<UserListItemDashBoardProps> = ({ employee }) => {
    return (
        <div className="flex flex-row justify-center items-center p-10 gap-10">
            <div className="w-3 h-3 bg-ridelGreen transform -rotate-y-180 rounded-full"></div>
            <div className="text-base" style={{ fontFamily: 'Rubik' }}>
                {employee.name}
            </div>
            <div className="text-base mx-auto" style={{ fontFamily: 'Rubik' }}>
                {employee.email}
            </div>
        </div>
    );
};

export default UserListItemDashBoard;