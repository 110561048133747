import React from 'react';
import LateCancelIcon from '../../../../images/Icons/status-icon-alert.png';

interface Props {
    
}

const LateCancel: React.FC<Props> = () => {
    return (
        <div className="flex flex-row items-center gap-5">
            <div className=" font-semibold text-base flex items-center text-errorRed" style={{ fontFamily:'rubik' }}>
                LATE CANCEL
            </div>
            <div>
                <img src={LateCancelIcon} />
            </div>
        </div>
    );
};

export default LateCancel;