import React from 'react';
import FinishedIcon from '../../../../images/Icons/status-icon-done.png';

interface Props {
    
}

const Finished: React.FC<Props> = () => {
    return (
    <div className="flex flex-row items-center gap-5">
        <div className=" font-semibold text-base flex items-center text-ridelGreen" style={{ fontFamily:'Rubik'}}>
            FINISHED
        </div>
        <div className='items-center'>
            <img src={FinishedIcon}/>
        </div>
    </div>
    );
};

export default Finished;