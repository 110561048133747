import { CompanyInfo } from '../Register/types'; 
import DynamicForm, { FormProps } from '../../components/Forms/RegisterCompanyForm'; 
import {registerCompany} from '../../api/company';
const RegisterView: React.FC = () => {
    
   
    const formFields = {
        companyName: {
            label: 'Company Name',
            placeholder: 'Firmanavn',
        },
        companyOrgNumber: {
            label: 'Company Org Number',
            placeholder: 'Organisasjonsnummer',
        },
        companyAddress: {
            label: 'Company Address',
            placeholder: 'Postadresse',
        },
        companyCity: {
            label: 'Company City',
            placeholder: 'Poststed',
        },
        companyZip: {
            label: 'Company Zip',
            placeholder: 'Postnummer',
        },
    };
    const formProps: FormProps = { fields: formFields, onSubmit: (data: CompanyInfo) => registerCompany(data), buttonText:'Registrer' } 
    

    return (
        <div className='flex flex-col justify-center items-center p-2 gap-2  w-screen h-screen' >
            <DynamicForm {...formProps} />
        </div>
    );
};
export default RegisterView;