import React from 'react';
import {FaEllipsisH,FaCircle } from 'react-icons/fa';
import LateCancel from '../BookingStatusTypes/LateCancel';
import Canceled from '../BookingStatusTypes/Canceled';
import Finished from '../BookingStatusTypes/Finished';
import { Trip } from './trip';
interface Props {
    trip: Trip
}


const TripElement: React.FC<Props> = (props:Props) => {        
    const trip = props.trip;

    return (
<tr className="flex items-center px-10 gap-20" style={{ fontFamily: 'Rubik' }}>
    <td>
        <div className="">
            <FaCircle style={{ fontSize: "0.5em" }}></FaCircle>
        </div>
    </td>
    <td>
        <div className="font-semibold text-base flex items-center">{trip.captureTime}</div>
    </td>
    <td>
        <div className="font-semibold text-base flex items-center underline">{trip.price} kr</div>
    </td>
    <td>
        <div className="font-semibold text-base flex items-center">
            {trip.statusName === "NoShow" && <LateCancel></LateCancel>}
            {trip.statusName === "BookingCompleted" && <Finished></Finished>}
            {trip.statusName === "CancellationConfirmed" && <Canceled></Canceled>}

        </div>
    </td>
    <td>
        <div className="flex items-center">
            <div className="font-semibold text-base">JOHN DOE</div>  
        </div>
    </td>
    <td>
        <div className="font-semibold flex items-center">
            {trip.dispatcherBookingNumber}
        </div>
    </td>
    <td className="flex gap-4">
        <div className="font-semibold text-base flex items-center">
            Nygata 27C 4626 Kristiansand S
        </div>
        <div className="font-semibold text-base flex items-center">
            Sagmyra 12B 4624 Kristiansand S
        </div>
    </td>
    <td>
        <div className="w-15 h-3.89 bg-gray-700 cursor-pointer">
            <a className='receipt-link' target="_blank" href={`${process.env.REACT_APP_RECEIPT_URL}/${trip.dispatcherBookingGuid}`} rel="noreferrer"><FaEllipsisH/></a> 
        </div>
    </td>
</tr>
    
    
    );
};

export default TripElement;