import React, { useState } from 'react';
import { logInUser } from '../../api/users';
import { User } from './types';
import { storageRead, storageSave } from '../../utils/Storage';
import RidelLogo from '../../images/RidelLogo.png';
import TextInputWithoutLabel from '../../components/Input/TextInputWithoutLabel';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { useUser } from '../../contexts/UserProvider';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const userContext = useUser();
    const handleEmailChange = (value: string) => {
        setEmail(value);
    };

    const handlePasswordChange = (value: string) => {
        setPassword(value);
    };

    const handleLogin = async () => {
        setLoading(true);
        try {
            const [error, loggedInUser] = await logInUser({ email: email, password: password });
            if (error) {
                console.log('Login failed');
                storageSave('user', null);
            } else {                    
                storageSave('user', loggedInUser);
                userContext.setUser(loggedInUser);
                if(loggedInUser!.passwordChangeRequired === true){
                    navigate('/updatePassword');
                }else{
                    storageSave('user', loggedInUser);
                    userContext.setUser(loggedInUser); 
                    navigate('/dashboard');  
                }
                
                
            }
        } catch (error) {
            console.log('Login failed');
        }
        setLoading(false);
    };

    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    };

    const handleRegister = async () => {
        navigate('/register');
    };
    const handleForgotPassword = async () => {
        navigate('/forgotPassword');
    };

    return (
        <div className='flex flex-col justify-center items-center p-2 gap-2  w-screen h-screen sm:w-300'>
            <div className='justify-between items-center p-8 space-y-8 bg-white rounded-lg shadow-md sm:w-300'>
                <div className='flex flex-col items-center gap-8 mx-auto sm:w-300'>
                    <div>
                        <img src={RidelLogo} alt="RidelLogo" className='w-115.5 h-42.58'/>
                    </div>
                    <div className='lg-flex xs:flex-none w-full flex-col   px-2 py-2 gap-6'>  
                    
                        <TextInputWithoutLabel className=''value={email} type="email" placeHolder='Brukernavn' onChange={handleEmailChange}  />
                        <TextInputWithoutLabel className='mt-5'value={password} type="password" placeHolder='Passord' onChange={handlePasswordChange}  />
                              
                        
                        <div className='flex items-center justify-left px-2 mt-5' style={{ fontFamily: 'gilroyextrabold' }}>
                            <input type="checkbox" checked={rememberMe} onChange={handleRememberMeChange} className='mr-2' />
                            <strong className='text-primary'>Husk meg</strong>
                        </div> 
                    </div>   
                </div>
                <div className='flex justify-center items-start px-8 gap-4 mx-auto'>
                    {loading === false && 
                    <>
                    <Link to="/register"><SecondaryButton onClick={handleRegister} text='REGISTRER'></SecondaryButton></Link>
                    <SecondaryButton onClick={handleForgotPassword} text='GLEMT PASSORD'></SecondaryButton>
                    <PrimaryButton onClick={handleLogin} text='LOGG INN'></PrimaryButton>
                    </>
                   }
                   {loading && <Spinner />}
                </div>
            </div>
        </div>
    );
};

export default Login;
