import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '../../src/images/Icons/home-icon.png'
import CarIcon from '../../src/images/Icons/car.icon.png'
import UserIcon from '../../src/images/Icons/user-ison.png'
import CompanyIcon from '../../src/images/Icons/company-icon.png'
import SupportIcon from '../../src/images/Icons/support-icon.png'
import LogOutIcon from '../../src/images/Icons/log-out-icon.png';



const Navbar: React.FC = () => {
    return (
        <div className="fixed top-0 left-0 flex flex-col justify-between items-center p-4 gap-10 h-full bg-ridelGreen shadow-md">
            <div className='flex flex-col justify-between items-center p-0 gap-12 mx-auto'>
                <Link to="/dashboard" >
                    <img src={HomeIcon}  style={{ height: '35px' }} />
                </Link>
                <Link to="/trips">
                    <img src={CarIcon}  style={{  height: '35px' }} />
                </Link>
                <Link to="/users">
                    <img src={UserIcon}  style={{  height: '35px' }} />
                </Link>
                <Link to="/companyinfo">
                    <img src={CompanyIcon}  style={{ height: '35px' }} />
                </Link>
                <Link to="/questions">
                    <img src={SupportIcon}  style={{ height: '35px' }} />
                </Link>
            </div>
            <Link to="/profile">
                <img src={LogOutIcon}  style={{  height: '35px' }} />
            </Link>
        </div>
    );
};

export default Navbar;