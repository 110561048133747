import React, { useEffect, useState } from 'react';
import UserListItemDashBoard from '../UserListItemDashBoard';
import UserListItemDetailed from '../UserListItemDetailed';
import AddIcon from '../../../../images/Icons/add-icon.png';
import { CreateEmployee, Employee } from './types';
import { useUser } from '../../../../contexts/UserProvider';
import { createCompanyEmployee, getCompanyUsersById,deleteCompanyEmployee } from '../../../../api/company';
import { create } from 'domain';
import AddEmployeeDialog from '../../../../components/Dialogs/AddEmployeeDialog';

interface Props {
    isDashboard?: boolean;
    companyUsers?: Array<Employee>;
}

const UserOverview: React.FC<Props> = ({ isDashboard = true, companyUsers = [] }) => {
    const userContext = useUser();
    const user = userContext.user;
    const [companyEmployees, setCompanyEmployees] = useState<Array<Employee>>([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        const fetchData = async () => {   
            const result = await getCompanyUsersById(user!.companyId, user!.token);
            setCompanyEmployees(result);
        };
        fetchData();
    }, []);
        
    const addUser = (newEmployee: CreateEmployee) => {
        createCompanyEmployee(newEmployee, user!.token).then((result: Employee|undefined) => {
            setShowDialog(false)
            if (result) {
                setCompanyEmployees(prevEmployees => [...prevEmployees, result]);
            }
        });
    };
    const onDeleteUser = async (userId: number) => {
        try {
            const deleted = await deleteCompanyEmployee(userId, user!.token);
            if (deleted) {
                setCompanyEmployees(prevEmployees => prevEmployees.filter(emp => emp.companyEmployeeId !== userId));
                console.log('Brukeren ble slettet vellykket');
            } else {
                console.log('Feilet å slette brukeren');
            }
        } catch (error) {
            console.error('Feil ved sletting av bruker:', error);
        }
    };

    return (
        <div className='flex flex-col p-6 gap-2 w-full bg-white shadow-md rounded-lg' style={{ fontFamily: 'gilroyextrabold' }}>
    <div className='flex p-0 gap-8 w-full border-b-2 border-ridelGreen justify-start'>
        <div className="text-2xl flex items-center w-full">
            USER OVERVIEW:
        </div>
    </div>
    <div className="flex flex-col items-start p-10 w-full">
        {isDashboard ? (
            <>
                {companyEmployees.map((employee) => (
                    <UserListItemDashBoard key={employee.companyEmployeeId} employee={employee} /> 
                ))}
            </>
        ) : (
            <>
                {companyEmployees.map((employee) => (
                    <UserListItemDetailed key={employee.companyEmployeeId} employee={employee} setCompanyEmployees={setCompanyEmployees} onDeleteUser={onDeleteUser}/> 
                ))}
            </>
        )}
    </div>
    {!isDashboard && (
        <div className='flex flex-col items-end p-2 gap-10 w-full'>
            <img onClick={() => setShowDialog(true)} className='cursor-pointer' src={AddIcon} style={{height:'50px', width:'50px'}} alt="Add Icon" />
            {showDialog && (
                <AddEmployeeDialog onClose={() => setShowDialog(false)} onFormCompleted={(newEmployee) => addUser(newEmployee)} companyId={user!.companyId} />
            )}
        </div>
    )}
</div>

    );
};

export default UserOverview;

