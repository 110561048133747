import React from 'react';

interface TextInputProps {
    value: string;
    type: string;
    onChange: (value: string) => void;
    className?: string; 
    placeHolder?: string;
}

const TextInputWithoutLabel: React.FC<TextInputProps> = ({ value, type, onChange, className, placeHolder }) => {
        return (
                <div className={`flex items-center px-4 py-2 bg-white shadow-md rounded-full ${className}`}>
                <input className='appearance-none flex-grow bg-transparent focus:outline-none w-full' 
                             placeholder={placeHolder}
                             type={type} 
                             value={value} 
                             onChange={(e) => onChange(e.target.value)}  
                />
            </div>
        );
};

export default TextInputWithoutLabel;