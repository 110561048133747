import React from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import { registerCardNets } from '../../api/nets';

interface Props {
    
}

const Nets: React.FC<Props> = (props: Props) => {
    

    const handleSubmit = async () => {
        var result = await registerCardNets();
        console.log(result);
        if (result.hostedPaymentPageUrl !== null) {
            window.open(result.hostedPaymentPageUrl);
        }
    };

    return (
        <div>
            <PrimaryButton text="Legg til firmakort" onClick={handleSubmit}></PrimaryButton>
        </div>
    );
};

export default Nets;