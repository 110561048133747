import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import TripList from '../Dashboard/components/TripList';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import RidelLogo from '../../images/RidelLogo.png'
import withAuth from '../../hoc/withAuth';
import { getCompanyReport } from '../../api/company';
import { useUser } from '../../contexts/UserProvider';
import Spinner from '../../components/Spinner/Spinner';
interface Props {
    
}

const TripsView: React.FC<Props> = () => {
    
    const userContext = useUser();
    const [loading, setLoading] = useState(false);
    const exportFile = async () => 
    {
    setLoading(true);
    var result = await getCompanyReport('2023-03-15T09:50:29.066Z','2024-03-15T09:50:29.066Z', userContext.user!.companyId,userContext.user!.token);
    setLoading(false);
    }
    return(
        <div>
            <Navbar />
            <div className="flex ml-12 flex-col items-start p-16 gap-6 bg-gray-100 flex-none order-1 self-stretch flex-grow-1">
                <TripList onClickExport={exportFile} showExportButton={true}/>
                 {loading && <Spinner/>}
                <div className='flex justify-end gap-10 w-full mt-10'>
                <img src={RidelLogo} style={{width:'115.5px', height:'42.58px'}}></img>
            </div>
            </div>
        </div>
    );
};

export default withAuth(TripsView);