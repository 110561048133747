import React, { useState } from 'react';
import TextInputWithoutLabel from '../../components/Input/TextInputWithoutLabel';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { forgotPassword } from '../../api/users';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [response, setResponse] = useState('');

    const handleEmailChange = (value: string) => {
        setEmail(value);
    };

    const handleSubmit = async () => {
        try {
            const [error, responseData] = await forgotPassword(email);
            if (error) {
                setResponse('Noe gikk galt. Vennligst prøv igjen.');
            } else {
                setResponse(responseData);
            }
        } catch (error) {
            console.log('Noe gikk galt. Vennligst prøv igjen.');
        }
    };

    return (
        <div className='flex flex-col justify-center items-center p-2 gap-2  w-screen h-screen sm:w-300'>
            <div className='justify-between items-center p-8 space-y-8 bg-white rounded-lg shadow-md sm:w-300'>
                <div className='flex flex-col items-center gap-8 mx-auto sm:w-300'>
                    <div>
                        <h1>Glemt passord</h1>
                    </div>
                    <div className='lg-flex xs:flex-none w-full flex-col px-2 py-2 gap-6'>
                        {typeof response === 'string' && response !== ''? (
                            <p>{response}</p>
                        ) : (
                            <TextInputWithoutLabel className='' value={email} type="email" placeHolder='Brukernavn' onChange={handleEmailChange} />
                        )}
                    </div>
                </div>
                <div className='flex justify-center items-start px-8 gap-4 mx-auto'>
                    <SecondaryButton onClick={handleSubmit} text='Søk' />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
