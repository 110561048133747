import React from 'react';

interface ButtonProps {
    onClick: () => void;
    text: string;
    icon?: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, text, icon }) => {
    return (
        <button onClick={onClick} className='p-3 flex justify-center items-center box-border px-4 py-2 gap-2 w-48 h-10 bg-ridelGreen border-2 rounded-full text-whiteBackground' style={{ fontFamily: 'gilroyextrabold, sans-serif' }}>
            {text}
            {icon && <img src={icon} alt='icon' style={{ width: '20px' , height: '19px'}}/>}
        </button>
    );
};

export default Button;