import { CreateEmployee } from "../../views/Dashboard/components/UserOverView/types";
import PrimaryButton from "../Buttons/PrimaryButton"; 
import SecondaryButton from "../Buttons/SecondaryButton";
import TextInputWithLabel from "../Input/TextInputWithLabel";
import { useState } from "react";
    



const AddEmployeeDialog: React.FC<{ onClose: () => void , onFormCompleted: (newEmployee:CreateEmployee) => void, companyId:number}> = ({ onClose, onFormCompleted,companyId }) => {

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');    
    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        const newEmployee: CreateEmployee = {
            companyId: companyId, 
            name: name,
            phoneNumber: phoneNumber,
            email: email
        };
        onFormCompleted(newEmployee);
        onClose();
    };

    console.log("AddEmployeeDialog");
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-whiteBackground bg-opacity-50">
            <div className="bg-white p-8 rounded-lg">
                <h2>Legg til ny ansatt</h2>
                <TextInputWithLabel label="Navn" value={name} onChange={setName}/>
                <TextInputWithLabel label="Telefonnummer" value={phoneNumber} onChange={setPhoneNumber}/>
                <TextInputWithLabel label="E-post" value={email} onChange={setEmail} />
                <PrimaryButton text="Legg til" onClick={handleSubmit}></PrimaryButton>
                <SecondaryButton text="Lukk" onClick={onClose}></SecondaryButton>
            </div>
        </div>
    );
};

export default AddEmployeeDialog;