import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './views/Login/Login';
import Dashboard from './views/Dashboard/Dashboard';
import Register from './views/Register/RegisterView';
import TripsView from './views/Trips/TripsView';
import UsersView from './views/Users/UsersView';
import CompanyInfoView from './views/CompanyInfo/CompanyInfoView';
import RegistrationCompleted from './views/Register/RegistrationCompleted';
import UpdatePasswordView from './views/UpdatePassword/UpdatePasswordView';
import ForgotPassword from './views/Login/ForgotPassword';

function App() {
  return (
    
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/trips" element={<TripsView />} />
            <Route path="/users" element={<UsersView />} />
            <Route path="/companyinfo" element={<CompanyInfoView />} />
            <Route path="/register" element={<Register />}/>
            <Route path="/register/success" element={<RegistrationCompleted />} />
            <Route path="/updatePassword/:token" element={<UpdatePasswordView />} />
            <Route path='/forgotPassword' element={<ForgotPassword></ForgotPassword>} />
          </Routes>
        </div>
      </BrowserRouter>
  );
}



export default App;