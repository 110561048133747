import { CompanyInfo } from '../../src/views/Register/types'; 
import { CreateEmployee, EditEmployee, Employee } from '../views/Dashboard/components/UserOverView/types';
import { useUser } from '../contexts/UserProvider';
import { createBearerTokenHeader } from '../api/headers';


const url = process.env.REACT_APP_API_URL;

export const registerCompany = (companyInfo: CompanyInfo) => {
    fetch(`${url}/company/create`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(companyInfo)
    })
        .then(response => response.json())
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getCompanyInfoById = (id: number, userToken: string): Promise<CompanyInfo> => {
    return fetch(`${url}` +'/company' +  '/' + id, {
        method: 'GET',
        headers: createBearerTokenHeader(userToken),
    })
        .then(response => response.json())
        .then(result => {
            return result as CompanyInfo;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
}

export const getCompanyUsersById = (id: number, userToken: string): Promise<Array<Employee>> => {
    return fetch(`${url}` +'/company' +  '/' + id + '/employees', {
        method: 'GET',
        headers: createBearerTokenHeader(userToken),
    })
        .then(response => response.json())
        .then(result => {
            return result as Array<Employee>;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
}

export const deleteCompanyEmployee = async (id: number, userToken: string): Promise<boolean> => {
    try {
        const response = await fetch(`${url}/employee/${id}`, {
            method: 'DELETE',
            headers: createBearerTokenHeader(userToken),
        });

        if (!response.ok) {
            throw new Error('Failed to delete company user');
        }

        const result = await response;
        console.log(result);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const createCompanyEmployee = async (employee:CreateEmployee , userToken: string): Promise<Employee | undefined> => {
    try {
        const response = await fetch(`${url}/employee`, {
            method: 'POST',
            body: JSON.stringify(employee),
            headers: createBearerTokenHeader(userToken),
        });

        if (!response.ok) {
            throw new Error('Failed to delete company user');
        }

        const result = await response.json();
        console.log(result);
        return result;
    } catch (error) {
        console.error(error);
    }
}

export const editCompanyEmployee = async (employee:EditEmployee , userToken: string, id:number): Promise<boolean> => {
    try {
        const response = await fetch(`${url}/employee/${id}`, {
            method: 'PUT',
            body: JSON.stringify(employee),
            headers: createBearerTokenHeader(userToken),
        });

        if (response.status === 204) {
            console.log('Company info updated successfully');
            return true;
        } else {
            console.log('Failed to update company info:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while updating company info:', error);
        return false;
    }
}


export const updateCompanyInfo = async (companyInfo: CompanyInfo, userToken: string, companyId: number): Promise<boolean> => {
    try {
        companyInfo.companyId = companyId;
        const response = await fetch(`${url}/company/${companyId}`, {
            method: 'PUT',
            body: JSON.stringify(companyInfo),
            headers: createBearerTokenHeader(userToken),
        });

        if (response.status === 204) {
            console.log('Company info updated successfully');
            return true;
        } else {
            console.log('Failed to update company info:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while updating company info:', error);
        return false;
    }
}
export const getTotalAmountSpentForMonth = async (companyId:number,fromdate: Date,toDate:Date, userToken:string): Promise<number> =>{
    try{
        return fetch(`${url}/company/GetCompanySpendingsForMonth?companyId=${companyId}`,{
            method: 'POST',
            body: JSON.stringify({
                fromDate: fromdate,
                toDate: toDate
            }),
            headers: createBearerTokenHeader(userToken)
        })
        .then(response => response.json())
        .then(result => {
            return result as number;
        })
    }
    
    catch(error){
        console.log(error);
        return 0;
    }
}

export const getTotalTripsForMonth = async (companyId:number,fromdate: string,toDate:string, userToken:string): Promise<Number> =>{
    try{
        return fetch(`${url}/company/GetCompanyTotalTripsForMonth?companyId=${companyId}`,{
            method: 'POST',
            body: JSON.stringify({
                fromDate: fromdate,
                toDate: toDate
            }),
            headers: createBearerTokenHeader(userToken)
        })
        .then(response => response.json())
        .then(result => {
            return result as Number;
        })
    }
    
    catch(error){
        console.log(error)
        return 0;
    }
}

export const getCompanyReport = async (fromDate: string, toDate: string, companyId: number, userToken: string): Promise<boolean> => {
    try {
        const response = await fetch(`${url}/company/getreport?companyId=${companyId}`, {
            method: 'POST',
            body: JSON.stringify({
                from: fromDate,
                to: toDate
            }),
            headers: createBearerTokenHeader(userToken),
        });

        if (response.status === 200) {
            console.log('File created successfully');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'report.xlsx'; 
            a.click();
            window.URL.revokeObjectURL(url);
            return true;
        } else {
            console.log('Failed to create file', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while getting report:', error);
        return false;
    }
}


